import React, { Component } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import TheToastWrapper from "./containers/TheToastWrapper";
import "./scss/style.scss";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const EmailVerification = React.lazy(() =>
  import("./views/pages/Verification/EmailVerification")
);
const ForgotPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ForgotPassword")
);
const ResetPassword = React.lazy(() =>
  import("./views/pages/forgotPassword/ResetPassword")
);

class App extends Component {
  render() {
    return (
      <TheToastWrapper>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/forgot-password"
                name="Forgot Password Page"
                render={(props) => <ForgotPassword {...props} />}
              />
              <Route
                exact
                path="/reset-password"
                name="Reset Password Page"
                render={(props) => <ResetPassword {...props} />}
              />
              {/* <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} /> */}

              <Route
                exact
                path="/customer/email-verification/:token"
                name="Verification"
                render={(props) => <EmailVerification {...props} />}
              />

              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => <Page404 {...props} />}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => <Page500 {...props} />}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => <TheLayout {...props} />}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </TheToastWrapper>
    );
  }
}

export default App;
