import { combineReducers } from "redux";
import sidebarShowReducer from "./sidebarShowReducer";
import authReducer from "./authReducer";
import categoryListReducer from "./categoryListReducer";
import passwordResetReducer from "./passwordResetReducer";
import customerListReducer from "./customerListReducer";
import pcodeListReducer from "./pcodeListReducer";
import districtListReducer from "./districtListReducer";
import localAdminListReducer from "./localAdminListReducer";
import stateListReducer from "./stateListReducer";
import detailsReducer from "./detailsReducer";
import bowserListReducer from "./bowserListReducer";
import driverListReducer from "./driverListReducer";
import orderListReducer from "./orderListReducer";
import BdeListReducer from "./BdeListReducer";
import complaintListReducer from "./complaintListReducer";
import offerListReducer from "./offerListReducer";
import notificationListReducer from "./notificationListReducer";
import reportsReducer from "./reportsReducer";

export default combineReducers({
  sidebarShowReducer,
  authReducer,
  categoryListReducer,
  passwordResetReducer,
  customerListReducer,
  pcodeListReducer,
  districtListReducer,
  localAdminListReducer,
  stateListReducer,
  detailsReducer,
  bowserListReducer,
  driverListReducer,
  orderListReducer,
  BdeListReducer,
  complaintListReducer,
  offerListReducer,
  notificationListReducer,
  reportsReducer,
});
