import React from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const TheToastWrapper = (props) => {

  return (
    <>
      <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      pauseOnHover
      
      />

      {props.children}
    </>
  )
}

export default TheToastWrapper
