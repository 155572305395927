export default (state = { loader:false, result:null, error:null, data:[], totalCount:0}, action) => {
    switch (action.type) {
        case 'DRIVER_FETCH_ACTION_BEGINS':
          return { loader:true, result:null, error:null, data:[...state.data], totalCount:state.totalCount }

        case 'DRIVER_FETCH_ACTION_SUCCESS':
          return { loader:false, result:action.payload.message, error:null, data:action.payload.data, totalCount:action.payload.count}
        case 'DRIVER_FETCH_ACTION_FAILURE':
          return { loader:false, result:null, error:action.payload.message, data:[], totalCount:0 }

        case 'DRIVER_FETCH_ACTION_CLEAR':
          return { loader:false, result:null, error:null, data:[], totalCount:0 }


      

        default:
          return state
      }
   }