export default (state = { loader:false, result:null, error:null, otp:null}, action) => {
    switch (action.type) {
        case 'RESET_REQUEST_ACTION_BEGINS':
          return { loader:true, result:null, error:null, otp:null }
        case 'RESET_REQUEST_ACTION_SUCCESS':
          return { loader:false, result:action.payload.email, error:null, otp:action.payload.otp }
        case 'RESET_REQUEST_ACTION_FAILURE':
          return { loader:false, result:null, error:action.payload, otp:null }


        case 'RESET_REQUEST_ACTION_CLEAR':
          return { loader:false, result:null, error:null, otp:null}
        case 'RESET_REQUEST_ERROR_CLEAR':
          return { loader:false, result:state.result, error:null, otp:state.otp}

        default:
          return state
      }
   }