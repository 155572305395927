export default (state = { loader:false, result:null, error:null, data:{}}, action) => {
    switch (action.type) {
        case 'DETAILS_FETCH_ACTION_BEGINS':
          return { loader:true, result:null, error:null, data:{} }

        case 'DETAILS_FETCH_ACTION_SUCCESS':
          return { loader:false, result:action.payload.message, error:null, data:action.payload.data}
        case 'DETAILS_FETCH_ACTION_FAILURE':
          return { loader:false, result:null, error:action.payload.message, data:{} }

        case 'DETAILS_FETCH_ACTION_CLEAR':
          return { loader:false, result:null, error:null, data:{} }


      

        default:
          return state
      }
   }