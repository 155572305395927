export default (
  state = {
    loader: false,
    result: null,
    error: null,
    data: [],
    totalCount: 0,
    status: "read",
  },
  action
) => {
  switch (action.type) {
    case "NOTIFICATION_FETCH_ACTION_BEGINS":
      return {
        loader: true,
        result: null,
        error: null,
        data: [...state.data],
        totalCount: state.totalCount,
        status: "read",
      };

    case "NOTIFICATION_FETCH_ACTION_SUCCESS":
      return {
        loader: false,
        result: action.payload.message,
        error: null,
        data: action.payload.data,
        totalCount: action.payload.count,
        status: "read",
      };
    case "NOTIFICATION_UPDATE_ACTION_SUCCESS":
      const oldData = [...state.data];
      oldData.push(action.payload.Item);
      return {
        loader: false,
        result: action.payload.message,
        error: null,
        data: oldData,
        totalCount: state.totalCount + 1,
        status: "new",
      };
    case "NOTIFICATION_FETCH_ACTION_FAILURE":
      return {
        loader: false,
        result: null,
        error: action.payload.message,
        data: [],
        totalCount: 0,
        status: "read",
      };

    case "NOTIFICATION_FETCH_ACTION_CLEAR":
      return {
        loader: false,
        result: null,
        error: null,
        data: [],
        totalCount: 0,
        status: "read",
      };
    case "NOTIFICATION_STATUS_CHANGE":
      return {
        ...state,
        status: "read",
      };

    default:
      return state;
  }
};
