export default (state = { loader:false, result:null, error:null}, action) => {
    switch (action.type) {
        case 'LOGIN_ACTION_BEGINS':
          return { loader:true, result:null, error:null }
        case 'LOGIN_ACTION_SUCCESS':
          return { loader:false, result:action.payload, error:null }
        case 'LOGIN_ACTION_FAILURE':
          return { loader:false, result:null, error:action.payload }


        case 'LOGOUT_ACTION_SUCCESS':
          return { loader:false, result:action.payload, error:null}
        case 'LOGIN_ACTION_CLEAR':
          return { loader:false, result:null, error:null}

        default:
          return state
      }
   }