export default (state = { loader:false, result:null, error:null, data:[]}, action) => {
    switch (action.type) {
        case 'CAT_FETCH_ACTION_BEGINS':
          return { loader:true, result:null, error:null, data:[] }
        case 'CAT_FETCH_ACTION_SUCCESS':
          return { loader:false, result:action.payload.message, error:null, data:action.payload.categories }
        case 'CAT_FETCH_ACTION_FAILURE':
          return { loader:false, result:null, error:action.payload.message }

        case 'CAT_FETCH_ACTION_CLEAR':
          return { loader:false, result:null, error:null }


      

        default:
          return state
      }
   }